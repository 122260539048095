import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export type AnnotationType = 'warning' | 'info' | 'success' | 'warning-light';

@Component({
    selector: 'yuno-shared-ui-annotation',
    imports: [NgClass],
    template: `
		<div
			class="flex w-full flex-col justify-start rounded-lg border-2 bg-opacity-95 px-3 py-2"
			[ngClass]="className">
			<ng-content></ng-content>
		</div>
	`,
    styles: ``,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoSharedUiAnnotationComponent {
	@HostBinding('class') hostClass = 'inline-block w-full';

	private _type: AnnotationType;
	@Input() set type(type: AnnotationType) {
		this._type = type;
	}

	get type(): AnnotationType {
		return this._type;
	}

	get className(): string {
		switch (this.type) {
			case 'warning':
				return 'text-red-500 bg-red-100 border-red-300';
			case 'warning-light':
				return 'text-red-40 bg-red-50 border-red-100';
			case 'success':
				return 'text-emerald-500 bg-emerald-50 border-emerald-300';
			case 'info':
			default:
				return 'text-sky-500 bg-sky-50 border-sky-300';
		}
	}
}
